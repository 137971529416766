.packages-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15rem auto;
  max-width: 800px;
}

.packages-title {
  text-align: center;
  margin-bottom: 4rem;
  /* Additional styling for the title as needed */
}

.packages-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  width: 100%; /* Ensure it takes up the full width of .packages-section */
}

.package {
  width: 100%;
  height: 200px;
  background: var(--onyx); /* Ensure this matches your color scheme */
  backdrop-filter: blur(30px);
  padding: 20px;
  transition: transform 0.3s ease;
  text-align: center;
  max-width: 350px;
}

.package-text {
  margin: 1rem;
}

.package:hover {
  transform: translateY(-10px);
}

@media (min-width: 1901px) {
  .package {
    max-width: 350px;
  }
  .packages-container {
    max-width: calc(350px * 2 + 1.5rem);
  }
}

@media (max-width: 970px) {
  .packages-container {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

  
  
  