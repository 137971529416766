/* Header Layout */
.header::after {
  content: '';
  position: absolute;
  bottom: -100px;
  width: 100%;
  height: 100px;
  background: linear-gradient(to bottom, #030303c3, transparent);
}

.top-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}

.top-section.top-scrolled {
  backdrop-filter: blur(10px);
  /* transition: background-color 0.8s ease; */
  /* background-color: rgba(0, 0, 0, 0.1); */
}


.logo {
  display: flex;
  align-items: center;
  font-size: 2rem;
}


.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
}

.nav-links li {
  margin: 0 1rem;
  cursor: pointer;
}

.nav-links a {
  color: var(--platinum);
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.5s ease;
}

.nav-links a:hover {
  color: var(--dim-gray);
}

.hamburger-menu {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  padding-right: 2rem;
}

.hamburger-menu .bar {
  width: 25px;
  height: 3px;
  background-color: var(--platinum);
  margin: 5px 0;
  transition: 0.5s;
}

.hero-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  height: calc(100vh - 1.5rem);
  padding: 3rem;
}

.title {
  font-size: 3.5rem;
  font-family: 'Poppins', sans-serif;
  color: var(--platinum);
  font-weight: normal;
  text-align: right;
}

.subtitle {
  font-size: 1.5rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  color: var(--platinum);
  text-align: right;
  /* text-indent: 2.5%; */
}

/* Responsive Design */
@media (max-width: 1200px) {
  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 4rem; /* Adjust based on the height of your top-section */
    right: 0.2rem;
    left: auto;
    overflow: hidden;
    transition: max-height 0.8s ease;
    max-height: 0; /* Initially no height */
    z-index: 2;
  }

  .nav-links.nav-active {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    top: 4rem;
    max-height: 500px; /* Adjust this value based on your content size */
  }

  .nav-links li {
    width: 100%;
    /* border-bottom: 1px solid var(--onyx); */
  }

  .nav-links a {
    padding: 0.75rem;
    display: block;
    color: var(--platinum);
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.5s ease;
    text-align: right;
  }

  .nav-links a:hover {
    color: var(--dim-gray);
  }

  .hamburger-menu {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    padding-right: 2rem;
  }

  .hero-section {
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
  }

  .intro-text {
    max-width: 100%;
  }

  .title {
    font-size: 2.8rem;
  }

  .subtitle {
    font-size: 1.3rem;
  }
}

@media (max-width: 680px) {
  .title {
    font-size: 2rem; /* Adjust size as needed to prevent wrapping */
  }

  .subtitle {
    font-size: 1rem; /* Adjust size as needed */
  }
}

@media (max-width: 1000px) {
  .top-section.top-scrolled {
    backdrop-filter: blur(0px);
  }
}
