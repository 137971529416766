/* Services.css */
.services-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 10rem;
  }
  
  .services-heading {
    font-family: 'Poppins', sans-serif;
    text-align: center;
  }
  
  .services-container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    
  }
  
  .services-column {
    display: flex;
    width: 270px;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
    text-align: left;
  }
  
  .services-subheading {
    font-family: 'Poppins', sans-serif;
    margin: 1rem;
    margin-bottom: 0.5rem;
  }

  .subheading-icon {
    width: 40px;
    height: 40px;
  }
  
  .services-list {
    list-style: none;
    padding: 0;
  }
  
  .services-list li {
    display: flex;
    align-items: center;
    margin: 1rem 0rem;
  }
  
  .services-list img {
    width: 10px;
    height: 10px;
    margin-right: 1rem;
  }
  
  .services-list span {
    font-family: 'Poppins', sans-serif;
    color: var(--platinum);
  }
  
  
  @media (max-width: 768px) {
    .services-container {
      flex-direction: column;
    }
    .services-column {
      align-items: center;
      margin: 0;
    }
  }
  