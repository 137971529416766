:root {
  --platinum: #dfdfdfff;
  --silver: #b4b4b4ff;
  --dim-gray: #666766ff;
  --onyx: #3f3f3fff;
  --eerie-black: #202020ff; 

  --background-color: #030303;

  --eerie-black-transparent: rgba(32, 32, 32, 0.774);
  --onyx-transparent-card: rgba(63, 63, 63, 0.229);

  --eletric-blue: #2b1cff;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&family=Taviraj:wght@100;200;300;400&display=swap');

html, body, * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Setting base font size and line height */
html {
  font-size: 100%; /* 16px */
  line-height: 1.6;
  scroll-behavior: smooth;
}

body {
  background-color: var(--background-color);
  background-repeat: repeat;
  color: var(--platinum);
  font-family: 'Poppins', sans-serif;
}

@media (max-width: 768px) {
  html {
    font-size: 90%; /* Reduce font size for smaller devices */
  }

  body {
    line-height: 1.4; /* Smaller line height for better readability on smaller screens */
  }
}

/* Global container styles */
.container {
  width: 100%;
  max-width: 80vw;
  margin: 0 auto;
  padding: 0 15px;
}

/* Additional styles can be added here as needed */
