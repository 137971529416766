.offering-container {
  margin: 5rem auto;
  max-width: 1000px;
  text-align: center; 
}

.offering-title {
  margin-bottom: 4rem; 
  text-align: center;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  justify-content: center;
}

.card {
  width: 100%;
  height: 350px;
  background-color: var(--eerie-black-transparent);
  text-align: left;
  max-width: 300px;
}

.card-image {
  width: 100%;
  height: auto;
  max-height: 25%;
  object-fit: cover;
}

.card-text {
  margin: 1rem;
  margin-top: 3rem;
}

@media (min-width: 1901px) {
  .card {
    max-width: 350px;
  }
  .cards-container {
    max-width: calc(350px * 3 + 1.5rem * 2); 
  }
}

@media (max-width: 970px) {
  .cards-container {
    grid-template-columns: 1fr;
    justify-items: center; 
  }
}

