.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }

  .about-image {
    display: inline-block;
    text-align: center;
  }

  .about h4 {
    margin: 1rem;
  }
  
  #profile {
    max-width: 20%;
    height: auto;
    border-radius: 50%;
  }
  
  .social-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
    margin-top: 1rem;
  }
  
  .social-icon {
    width: 1.5rem;
    height: auto;
  }
  